const Skills = () => {
  return (
    <>
      <h2 className="title">Skills</h2>
      <p className="text">HTML, CSS, javaScript, typeScript,  React js, Next js, Redux, Vite, Restful API, GraqhQl, WebSocket</p>
      <p className="text pb-10">Material Ui, Ant design, Tailwind, BootStrap, Sass, Git</p>
    </>
  );
};

export default Skills;
